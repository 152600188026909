<template>
  <div class="container">
    <FloatingButton icon="fas fa-home" @click="goBack"/>
    <LoadingFullPage v-if="isLoading"/>
    <div class="row" v-else>
        <Title text="Email" />
        <div class="col s12" v-for="user in users" :key="user">
            <p class="white-text">{{ user }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Admin/Title'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Title, FloatingButton, LoadingFullPage },
    setup(){
        
        const {router, isLoading, } = useBoilerplate(true);

        const users = ref([]);
        
        const goBack = () => router.push({name: "AdminPanel"});
        
        onMounted(async () => {
            try {
                const url = '/admin/getAllUsers';
                const res = await fetch(url, {cache: "no-cache"});
                const data = await res.json();
                data.users.forEach(user => users.value.push(user));
                isLoading.value = false;
            } catch (err) {
                console.log(err);
                isLoading.value = false;
            }
        })
        return { goBack, isLoading, users }
    }
}
</script>

<style scoped>
  .blue-text{ cursor: pointer; }
</style>